import React from "react";
import { Link } from "gatsby";
import { Formik, Form, Field } from "formik";
import { Link as ScrollLink } from "react-scroll";

const TermsDetails = ({ theme }) => {
  // const messageRef = React.useRef(null);

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }
  const sendMessage = (ms) => new Promise((r) => setTimeout(r, ms));

  return (
    <section className="blog-pg single section-padding pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="post">
              {/* <div className="img">
                <img src="/img/blog/single.jpg" alt="" />
              </div> */}
              <div className="content pt-60">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="cont">
                      <h4 className="extra-title">Use of the Services
</h4>
                      <div className="spacial">
                        <p>
                        Welcome to M2M Telecoms (“we”, “us”, or “our”). By using our website and online services, including any mobile applications (the “Services”), you agree to be bound by these terms and conditions (“Terms”). If you do not agree to these Terms, do not use the Services.
                        </p>
                      </div>
                    


                      <h4 className="extra-title">Use of the Services</h4>
                      <div className="spacial">
                        <p>
                        You may use the Services only in accordance with these Terms and all applicable laws. You may not use the Services for any illegal or unauthorized purpose, or in a way that could damage, disable, overburden, or impair the Services or interfere with any other party’s use of the Services.
You may not attempt to gain unauthorized access to the Services or any related systems or networks. You are responsible for maintaining the confidentiality of your account and password, and for all activities that occur under your account.
We reserve the right to modify or discontinue the Services, or any portion thereof, at any time without notice. We will not be liable to you or any third party for any modification, suspension, or discontinuance of the Services.
                        </p>
                      </div>

                    


                      <h4 className="extra-title">Content and Intellectual Property</h4>
                      <div className="spacial">
                        <p>
                        We may share the information we collect about you as follows:The Services may include text, images, graphics, audio, video, and other content (the “Content”). The Content is protected by copyright, trademark, and other intellectual property laws. You may not use the Content for any commercial purpose without our prior written consent.
You may not modify, reproduce, distribute, create derivative works of, or publicly display the Content without our prior written consent. You may not remove or alter any copyright, trademark, or other proprietary notices contained in the Content.
We reserve all rights in the Content that are not expressly granted to you in these Terms.
                        </p>
                      </div>

                   


                      
                      <h4 className="extra-title">User-Generated Content</h4>
                      <div className="spacial">
                        <p>
                        The Services may allow you to post, upload, or otherwise transmit content, including text, images, audio, and video (the “User-Generated Content”). You are solely responsible for your User-Generated Content and the consequences of posting or publishing it.
By posting or publishing User-Generated Content, you represent and warrant that:
                        </p>
                      </div>

                      <ul>
                        <li>
                        You have the right to do so, and
                        </li>
                        <li>
                        Your User-Generated Content does not violate these Terms or any applicable laws.
                        </li>
                      
                      <p>
                      We reserve the right to remove or edit any User-Generated Content at any time without notice. We are not responsible for the accuracy, completeness, or appropriateness of any User-Generated Content.
                      </p>
                  
                      </ul>

                      {/* <h4 className="extra-title">Privacy
</h4>
                      <div className="spacial">
                        <p>
                        Our Privacy Policy explains how we collect, use, and share information about you when you use the Services. By using the Services, you agree to the collection, use, and sharing of your information as described in our Privacy Policy.
                        </p>

                        </div> */}

                        <h4 className="extra-title">Disclaimer of Warranties

</h4>
                      <div className="spacial">
                        <p>
                        THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.



                        <br/>
                        <br/>
                        WE DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND WE WILL NOT BE LIABLE FOR ANY INTERRUPTIONS OR ERRORS.
                        </p>

                        <h4 className="extra-title">Privacy
</h4>
                      <div className="spacial">
                        <p>
                        Our Privacy Policy explains how we collect, use, and share information about you when you use the Services. By using the Services, you agree to the collection, use, and sharing of your information as described in our Privacy Policy.
                        </p>

                        </div>

                        <h4 className="extra-title">Limitation of Liability


</h4>
                      <div className="spacial">
                        <p>
                        WE WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE SERVICES, INCLUDING BUT NOT LIMITED TO INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, SPECIAL, OR CONSEQUENTIAL DAMAGES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), OR OTHERWISE, WILL NOT EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE SERVICES.
THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.

                     
                        </p>


                        </div>
                        </div>


                      {/* <div className="quotes text-center">
                        <p>
                          Never ever think of giving up. Winners never quit and
                          quitters never win. Take all negative words out of
                          your mental dictionary and focus on the solutions with
                          utmost conviction and patience. The battle is never
                          lost until you’ve abandon your vision.
                        </p>
                      </div> */}
                      {/* <div className="row">
                        <div className="col-md-6">
                          <div className="mb-10">
                            <img src="/img/blog/2.jpg" alt="" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-10">
                            <img src="/img/blog/3.jpg" alt="" />
                          </div>
                        </div>
                      </div> */}
                      {/* <p>
                        We all intend to plan ahead, but too often let the
                        day-to-day minutia get in the way of making a calendar
                        for the year. Sure, you can’t know every detail to
                        anticipate. Heck, you can’t know half the priorities
                        that will pop up in any particular month. But you can
                        plan for big picture seasonality, busy-times, and
                        events.
                      </p>
                      <div className="share-info">
                        <div className="social">
                          <a href="#0">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          <a href="#0">
                            <i className="fab fa-twitter"></i>
                          </a>
                          <a href="#0">
                            <i className="fab fa-behance"></i>
                          </a>
                        </div>
                        <div className="tags">
                          <a href="#0">Web</a>,<a href="#0">Themeforest</a>,
                          <a href="#0">ThemesCamp</a>
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="author">
                      <div className="author-img">
                        <img src="/img/blog/01.jpg" alt="" />
                      </div>
                      <div className="info">
                        <h6>
                          <span>author :</span> Jorden Griffin
                        </h6>
                        <p>
                          the main component of a healthy environment for self
                          esteem is that it needs be nurturing. The main compont
                          of a healthy environment.
                        </p>
                        <div className="social">
                          <a href="#0">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                          <a href="#0">
                            <i className="fab fa-twitter"></i>
                          </a>
                          <a href="#0">
                            <i className="fab fa-behance"></i>
                          </a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              {/* <div className="pagination">
                <span>
                  <a href="#0">Prev Post</a>
                </span>
                <span className="icon">
                  <Link to={`/blog/blog-dark`}>
                    <i className="fas fa-th-large"></i>
                  </Link>
                </span>
                <span className="text-right">
                  <a href="#0">Next Post</a>
                </span>
              </div> */}
{/* 
              <div className="comments-area">
                <h5>Comments :</h5>
                <div className="item">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self
                      esteem is that it needs be nurturing. The main compont of
                      a healthy environment.
                    </p>
                  </div>
                </div>
                <div className="item relped">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self
                      esteem is that it needs be nurturing. The main compont of
                      a healthy environment.
                    </p>
                  </div>
                </div>
                <div className="item">
                  <div className="comment-img">
                    <img src="/img/blog/01.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h6>
                      Jorden Griffin - <span> 6 Aug 2022</span>
                    </h6>
                    <span className="replay">
                      <ScrollLink
                        to="comment-form"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                      >
                        Replay <i className="fas fa-reply"></i>
                      </ScrollLink>
                    </span>
                    <p>
                      the main component of a healthy environment for self
                      esteem is that it needs be nurturing. The main compont of
                      a healthy environment.
                    </p>
                  </div>
                </div>
              </div> */}

              {/* <div className="comment-form" id="comment-form">
                <h5>Add Comment :</h5>
                <div className="form">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      comment: "",
                    }}
                    onSubmit={async (values) => {
                      await sendMessage(500);
                      alert(JSON.stringify(values, null, 2));
                      // Reset the values
                      values.name = "";
                      values.email = "";
                      values.comment = "";
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <Field
                                as="textarea"
                                placeholder="Your Comment"
                                name="comment"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Field
                                type="text"
                                placeholder="Your Name"
                                name="name"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <Field
                                type="email"
                                validate={validateEmail}
                                placeholder="Your Email"
                                name="email"
                              />
                              {errors.email && touched.email && (
                                <div>{errors.email}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group text-center">
                              <button
                                type="submit"
                                className={`nb butn ${
                                  theme
                                    ? theme === "light"
                                      ? "dark"
                                      : ""
                                    : "light"
                                } curve full-width`}
                              >
                                Comment
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsDetails;
